<template>
  <div class="errorModal" v-show="errMsgShow">
    <div class="error_container">
      <p v-html="errMsg" class="error_title"></p>
      <div @click="closeMsg" class="error_ok">戻る</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errMsgShow: {
      type: Boolean,
      default: false,
    },
    errMsg: {
      default: "",
    },
  },
  methods: {
    closeMsg() {
      this.$emit("update:errMsgShow", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/lib.scss";
.errorModal {
  position: fixed;
  z-index: 999999;
  .error_container {
    position: fixed;
    padding: 10px;
    width: 300px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    background: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 0px 9999px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 0px 9999px rgba(0, 0, 0, 0.5);
    .error_title {
      min-height: 24px;
      line-height: 24px;
    }
    .error_ok {
      margin: 0 auto;
      margin-top: 5px;
      width: 100px;
      height: 30px;
      text-align: center;
      color: white;
      border-radius: 5px;
      background-color: #fabf13;
      line-height: 30px;
      cursor: pointer;
    }
  }
}
@media screen and (min-width: 416px) {
  .errorModal {
    .error_container {
      padding: 10px;
      width: 400px;
      font-size: 12px;
      border-radius: 5px;
      .error_title {
        min-height: 24px;
        line-height: 24px;
      }
      .error_ok {
        margin-top: 5px;
        width: 100px;
        height: 30px;
        border-radius: 5px;
        line-height: 30px;
      }
    }
  }
}
</style>
